const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  var brd4 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-4, 10, 8, -2], keepaspectratio: true, axis:false, ticks:{visible:false},
  grid:true, showCopyright:false, showNavigation:false,
  pan:{enabled:false}, zoom:{enabled:false}});
  brd4.create('text', [0, 9, '<b> Square Formula <b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd4.canvasWidth/500.)}, fixed:true});
  var resize = function () {
      brd4.resizeContainer(brd4.containerObj.clientWidth, brd4.containerObj.clientHeight, true);
      brd4.fullUpdate();
      };
window.onresize = resize;
var i =0;
brd4.create('text', [0, 8., 'Area ABCD = (a+b)^2'],{visible:function(){if(i<=1){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
brd4.create('text', [0, 7.4, 'Area ABCD = a^2 + 2ab + b^2'],{visible:function(){if(i==1){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//brd4.create('text', [2.0, 8., 'a^2'],{visible:function(){if(i==1){return true} else{return false}}, fixed: true, strokeColor:'#95DD43', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//brd4.create('text', [2.4, 8., ' + '],{visible:function(){if(i==1){return true} else{return false}},fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//brd4.create('text', [2.7, 8., ' 2&times; '],{visible:function(){if(i==1){return true} else{return false}},fixed: true, strokeColor:'black',  CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//brd4.create('text', [3.1, 8., ' ab '],{visible:function(){if(i==1){return true} else{return false}},fixed: true, strokeColor:'#ff5935',  CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//rd1.create('text', [3.5, 8., ' + '],{visible:function(){if(i==1){return true} else{return false}},fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//brd4.create('text', [3.8, 8., 'b^2'],{visible:function(){if(i==1){return true} else{return false}},fixed: true, strokeColor:'#6691ff', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
brd4.create('text', [0., 8.2, '(a+b)^2 = 4^2 = 16 bins'],{visible:function(){if(i==2){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//
//the sides of the square measure
var total = 4;
//
//Extreme ponits of the square
var extreme1 = brd4.create('point', [0,0],{fixed:true, name:'A', size:0, label: {offset:[0, -15], fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var extreme2 = brd4.create('point', [total,0],{fixed:true, name:'B', size:0, label: {offset:[0, -15], fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var extreme3 = brd4.create('point', [total, total],{fixed:true, name:'C', size:0, label: {offset:[0, 15], fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
var extreme4 = brd4.create('point', [0,total],{fixed:true, name:'D', size:0, label: {offset:[0, 15],fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

//Black borders of the square
var border1 = brd4.create('segment', [extreme1, extreme2], {fixed: true, strokeColor: 'black'});
var border2 = brd4.create('segment', [extreme2, extreme3], {fixed: true, strokeColor: 'black'});
var border3 = brd4.create('segment', [extreme3, extreme4], {fixed: true, strokeColor: 'black'});
var border4 = brd4.create('segment', [extreme1, extreme4], {fixed: true, strokeColor: 'black'});

//Interior points on the borders
var dragMe =brd4.create('glider', [4,total, border3], {face:'circle', size:3, name:'Drag Me!', snapToGrid:function(){if(i==2){return true}else{return false}}, label: {offset: [0,25]}, strokeColor: 'black', fillColor: '#e1e1e1', fixed:false, visible:false});
var int1 =brd4.create('glider', [function(){return dragMe.X()}, 0, border1], {face:'square', size:3, name:'',strokeColor: 'black', fillColor: '#e1e1e1', visible:false});
var int2 =brd4.create('glider', [total,function() {return  total - dragMe.X()}, border2], {face:'square', size:3,strokeColor: 'black', fillColor: '#e1e1e1', name:'', visible:false});
var int4 =brd4.create('glider', [0,function() {return total - dragMe.X()}, border4], {face:'square', size:3, name:'', strokeColor: 'black', fillColor: '#e1e1e1',visible:false});

//Interior point
var interiorPoint = brd4.create('point', [function(){return dragMe.X()},function(){return total - dragMe.X()}],{face:'o' , name:'pp', size:2, strokeColor: 'black', fillColor: 'grey', withLabel:false, fixed:false, visible:false});

//Lines on the inside
var l1 = brd4.create('segment', [interiorPoint, int1], {fixed: false, strokeColor: 'black'});
var l2 = brd4.create('segment', [interiorPoint, int2], {fixed: false, strokeColor: 'black'});
var l3 = brd4.create('segment', [interiorPoint, dragMe], {fixed: false, strokeColor: 'black'});
var l4 = brd4.create('segment', [interiorPoint, int4], {fixed: false, strokeColor: 'black'});

//Polygons
brd4.create('polygon', [extreme1, int1, interiorPoint, int4],{highlight:false, fixed:true, strokeWidth:2, fillColor:'#ff5935', fillOpacity:0.25, borders:{fixed:true, strokeColor:'blue'},visible: true});
brd4.create('polygon', [int1, extreme2, int2, interiorPoint],{highlight:false,fixed:true, strokeWidth:2, fillColor:'#6691ff', fillOpacity:0.25, borders:{fixed:true, strokeColor:'blue'},visible: true});
brd4.create('polygon', [interiorPoint, int2, extreme3, dragMe],{highlight:false,fixed:true, strokeWidth:2, fillColor:'#ff5935', fillOpacity:0.25, borders:{fixed:true, strokeColor:'blue'},visible: true});
brd4.create('polygon', [int4, interiorPoint, dragMe, extreme4],{highlight:false,fixed:true, strokeWidth:2, fillColor:'#95DD43', fillOpacity:0.25, borders:{fixed:true, strokeColor:'blue'},visible: true});

brd4.create('text', [-3, 3., function(){return 'a^2 = ' + (dragMe.X()*dragMe.X()).toFixed(0) + ' bins'}],{visible:function(){if(i==2){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
brd4.create('text', [-3, 2., function(){return 'b^2 = ' + ((4-dragMe.X())*(4-dragMe.X())).toFixed(0) + ' bins'}],{visible:function(){if(i==2){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
brd4.create('text', [-3, 1., function(){return 'ab = ' + ((dragMe.X())*(4-dragMe.X())).toFixed(0) + ' bins'}],{visible:function(){if(i==2){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
brd4.create('text', [0., 7., function(){return 'a^2 + b^2 + 2 x ab = ' + (dragMe.X()*dragMe.X()).toFixed(0)+'+'+((4-dragMe.X())*(4-dragMe.X())).toFixed(0)+'+2 x '+((dragMe.X())*(4-dragMe.X())).toFixed(0) + ' = 16 bins'}],{visible:function(){if(i==2){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});

//

//Arrows on border 2
var offset = 0.5;
brd4.create('arrow', [[total + offset, 0.0], [total + offset, total]],{strokeColor:'black', strokeWidth: 1, fixed:true, firstArrow:true, lastArrow:true});
brd4.create('arrow', [[0, total+offset], [total, total + offset]],{strokeColor:'black', strokeWidth: 1, fixed:true, firstArrow:true, lastArrow:true});

var arr2_1 = brd4.create('point', [function(){return total + offset},function(){return extreme2.Y()}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
var arr2_2 = brd4.create('point', [function(){return total + offset},function(){return interiorPoint.Y()}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
var arr2_3 = brd4.create('point', [function(){return total + offset},function(){return total}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});

var arrBor2_1 = brd4.create ('arrow', [arr2_1, arr2_2], {visible:false, strokeColor:'black', strokeWidth: 1, fixed:true, firstArrow:true, lastArrow:true});
//var arrBor2_2 = brd4.create ('arrow', [arr2_2, arr2_1], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1});
var arrBor2_3 = brd4.create ('arrow', [arr2_2, arr2_3], {visible:false, strokeColor:'black', strokeWidth: 1, fixed:true, firstArrow:true, lastArrow:true});
//var arrBor2_4 = brd4.create ('arrow', [arr2_3, arr2_2], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1});

//Arrows on border 3
var arr3_1 = brd4.create('point', [function(){return extreme4.X()},function(){return total+offset}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
var arr3_2 = brd4.create('point', [function(){return dragMe.X()},function(){return total+offset}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
var arr3_3 = brd4.create('point', [total,function(){return total+offset}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});

var arrBor3_1 = brd4.create ('arrow', [arr3_1, arr3_2], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1,  firstArrow:true, lastArrow:true});
//var arrBor3_2 = brd4.create ('arrow', [arr3_2, arr3_1], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1});
var arrBor3_3 = brd4.create ('arrow', [arr3_2, arr3_3], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1,  firstArrow:true, lastArrow:true});
//var arrBor3_4 = brd4.create ('arrow', [arr3_3, arr3_2], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1});

//REDO AND UNDO
var redo = brd4.create('image', ['/assets/redo.svg', [2.5,-1.5], [1,1]], {visible: true, fixed: true});
redo.setLabel('Next Step')
redo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
redo.on('over', function () {this.label.setAttribute({visible:true});});
redo.on('out', function () {this.label.setAttribute({visible:false});});
var undo = brd4.create('image', ['/assets/undo.svg', [0.5,-1.5], [1,1]], {visible: true, fixed: true});
undo.setLabel('Previous Step')
undo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
undo.on('over', function () {this.label.setAttribute({visible:true});});
undo.on('out', function () {this.label.setAttribute({visible:false});});
var maxSteps = 2;

function updateStepTexts(){
    if (i<=0){

        dragMe.moveTo([4,4], 100);
        textInitial.setAttribute({visible: true});
		text0_1.setAttribute({visible: true});
		text0_2.setAttribute({visible: true});
    }
    if(i==1){

        dragMe.moveTo([3,4], 100);
        dragMe.setAttribute({visible:false});
        int1.setAttribute({visible:false});
		int2.setAttribute({visible:false});
		int4.setAttribute({visible:false});
        text1_1.setAttribute({visible: true});
        text1_2.setAttribute({visible: true});
        text1_3.setAttribute({visible: true});
        text1_4.setAttribute({visible: true});
        text1_5.setAttribute({visible: true});
        text1_6.setAttribute({visible: true});
        text1_7.setAttribute({visible: true});
        text1_8.setAttribute({visible: true});
        arrBor2_1.setAttribute({visible: true});
        //arrBor2_2.setAttribute({visible: true});
        arrBor2_3.setAttribute({visible: true});
       // arrBor2_4.setAttribute({visible: true});
        arrBor3_1.setAttribute({visible: true});
        //arrBor3_2.setAttribute({visible: true});
        arrBor3_3.setAttribute({visible: true});
        //arrBor3_4.setAttribute({visible: true});
		}
    if(i==2){

        dragMe.setAttribute({visible:true});
        int1.setAttribute({visible:true});
		int2.setAttribute({visible:true});
		int4.setAttribute({visible:true});

        text2_1.setAttribute({visible: true});
        text2_2.setAttribute({visible: true});
        text2_3.setAttribute({visible: true});
        text2_4.setAttribute({visible: true});
        text2_5.setAttribute({visible: true});
        text2_6.setAttribute({visible: true});
        text2_7.setAttribute({visible: true});
        text2_8.setAttribute({visible: true});

        arrBor2_1.setAttribute({visible: true});
        //arrBor2_2.setAttribute({visible: true});
        arrBor2_3.setAttribute({visible: true});
        //arrBor2_4.setAttribute({visible: true});

        arrBor3_1.setAttribute({visible: true});
       // arrBor3_2.setAttribute({visible: true});
        arrBor3_3.setAttribute({visible: true});
        //arrBor3_4.setAttribute({visible: true});

    }

}

function eraseText(){

    textInitial.setAttribute({visible: false});
    dragMe.setAttribute({visible:false});
    int1.setAttribute({visible:false});
	int2.setAttribute({visible:false});
	int4.setAttribute({visible:false});
    text0_1.setAttribute({visible:false});
	text0_2.setAttribute({visible:false});
    text1_1.setAttribute({visible: false});
    text1_2.setAttribute({visible: false});
    text1_3.setAttribute({visible: false});
    text1_4.setAttribute({visible: false});
    text1_5.setAttribute({visible: false});
    text1_6.setAttribute({visible: false});
    text1_7.setAttribute({visible: false});
    text1_8.setAttribute({visible: false});

    text2_1.setAttribute({visible: false});
    text2_2.setAttribute({visible: false});
    text2_3.setAttribute({visible: false});
    text2_4.setAttribute({visible: false});
    text2_5.setAttribute({visible: false});
    text2_6.setAttribute({visible: false});
    text2_7.setAttribute({visible: false});
    text2_8.setAttribute({visible: false});

    arrBor2_1.setAttribute({visible: false});
   // arrBor2_2.setAttribute({visible: false});
    arrBor2_3.setAttribute({visible: false});
    //arrBor2_4.setAttribute({visible: false});

    arrBor3_1.setAttribute({visible: false});
    //arrBor3_2.setAttribute({visible: false});
    arrBor3_3.setAttribute({visible: false});
    //arrBor3_4.setAttribute({visible: false});
}

redo.on('down',function(){
    i = i+1;
    if (i > maxSteps){
        i = maxSteps;
    }
	    eraseText();
    updateStepTexts();
});

undo.on('down',function(){
    i=i-1;
    if (i < 0){
        i = 0;
    }
    eraseText();
    updateStepTexts();
});

//Initial Step
var textInitial = brd4.create('text', [1.5, 2, '(a+b)^2'], {visible: true, fixed:true, fontSize:function(){return 48*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//FIRST STEP - VALUES IN LETTERS

//Values
var text1_1 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return interiorPoint.Y()/2}, 'ab'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_2 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return interiorPoint.Y()/2}, 'b^2'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_3 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, 'ab'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_4 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, 'a^2'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//Text arrows on border 2
var text1_5 = brd4.create('text', [function(){return total + offset + 0.1}, function(){return interiorPoint.Y()/2}, 'b'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_6 = brd4.create('text', [function(){return total + offset + 0.1}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, 'a'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
var text0_1 = brd4.create('text', [total+1.5*offset, total/2, 'a+b'], {visible: true, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text0_2 = brd4.create('text', [total/2, total+1.5*offset, 'a+b'], {visible: true, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//Text arrows on border 3
var text1_7 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return total + 1.5*offset}, 'a'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_8 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return  total + 1.5*offset},'b'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});


//SECOND STEP - VALUES IN NUMBERS

//Values
var text2_1 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return interiorPoint.Y()/2}, function(){return (interiorPoint.X()*interiorPoint.Y()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_2 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return interiorPoint.Y()/2}, function(){return ((total-interiorPoint.X())*(interiorPoint.Y())).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_3 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, function(){return (interiorPoint.X()*interiorPoint.Y()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_4 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, function(){return (interiorPoint.X()*interiorPoint.X()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//Text arrows on border 2
var text2_5 = brd4.create('text', [function(){return total + offset + 0.1}, function(){return interiorPoint.Y()/2}, function(){return (interiorPoint.Y()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_6 = brd4.create('text', [function(){return total + offset + 0.1}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, function(){return (total - interiorPoint.Y()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//Text arrows on border 3
var text2_7 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return total + offset + 0.15}, function(){return (interiorPoint.X()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_8 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return  total + offset + 0.15}, function(){return (total-interiorPoint.X()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});


    },
}
export default Boxes;
